<template>
  <div class="mx-auto">
    <div
      v-if="sliderTemplates.length > 0"
      class="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_70px,_black_calc(100%-70px),transparent_100%)]"
    >
      <ul
        ref="templates-slider"
        class="flex justify-center md:justify-start animate-infinite-scroll"
      >
        <li
          v-for="(template) in sliderTemplates"
          :key="template.id"
          class="mx-4 w-72 h-auto"
        >
          <SingleTemplate :template="template" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import SingleTemplate from '../templates/SingleTemplate.vue'

export default {
  components: { SingleTemplate },
  props: {},

  setup() {
    const templatesStore = useFormsTemplatesStore()

    onMounted(() => {
      if (templatesStore.getAll.length < 10) {
        noteFormsFetch('templates', { query: { limit: 10 } }).then((data) => {
          templatesStore.set(data)
        })
      }
    })

    return {
      templatesStore,
      templates: computed(() => templatesStore.content),
      sliderTemplates: computed(() => templatesStore.getAll.slice(0, 20)),
    }
  },

  data: () => ({}),

  watch: {
    templates() {
      this.$nextTick(() => {
        this.setInfinite()
      })
    },
  },

  methods: {
    setInfinite() {
      const ul = this.$refs['templates-slider']
      if (ul) {
        ul.insertAdjacentHTML('afterend', ul.outerHTML)
        ul.nextSibling.setAttribute('aria-hidden', 'true')
      }
    },
  },
}
</script>
